import React from 'react'

import _ from 'lodash'

import { Link as RouterLink } from 'react-router-dom'
import { useAnalytics } from 'use-analytics'

import clsx from 'clsx'

import { makeStyles, withStyles } from '@mui/styles'
import { Container, Grid, Box, Button } from '@mui/material'
import IconImmediateHelp from '../../../tenants/myservices/assets/images/icon-immediate-help.svg'
import IconSearchForService from '../../../tenants/myservices/assets/images/icon-search.svg'
import IconAnDSupport from '../../../tenants/myservices/assets/images/icon-alcohol-drug.svg'
import IconMentalSupport from '../../../tenants/myservices/assets/images/icon-mental-health.svg'

import { useConfig } from 'config/config'

const bb = 'sm'

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: '#f0f',
    // width: '100%',
    // margin: theme.spacing(5, 0),
  },
  container: {
    // margin: '0 auto',
  },
  navLink: {
    // textDecoration: 'none',
  },
  card: {
    // border: '1px solid #eee',
  },
  buttonContainer: {
    // padding: theme.spacing(5, 2),
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'flex-start',
    // minHeight: 280,
    // [theme.breakpoints.down(bb)]: {
    //   minHeight: 'auto',
    //   padding: theme.spacing(3, 2),
    // },
  },
  button: {},
  buttonIcon: {
    // marginTop: theme.spacing(2),
    // backgroundColor: theme.palette.primary.light,
    // color: theme.palette.secondary.contrastText,
    // borderRadius: '50%',
    // fontSize: 38,
    // width: 100,
    // height: 100,
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'center',
    // '& > i': {
    //   marginTop: 3,
    // },
    // [theme.breakpoints.down(bb)]: {
    //   marginTop: 0,
    //   fontSize: 20,
    //   width: 44,
    //   height: 44,
    // },
  },
  buttonLabel: {
    // marginTop: theme.spacing(4),
    // fontSize: 24,
    // fontWeight: 400,
    // textAlign: 'center',
    // color: theme.palette.text.secondary,
    // [theme.breakpoints.down(bb)]: {
    //   marginTop: theme.spacing(2),
    //   fontSize: 18,
    // },
  },
}))

// const HomeButton_ = ({ button }) => {
//   const classes = useStyles()
//   const { track } = useAnalytics()

//   const handleClick = () => {
//     track('homePageButtonClick', {
//       category: 'Navigation',
//       label: button.label,
//     })
//   }

//   return (
//     <div className={classes.button}>
//       <NavLink to={button.route} className={classes.navLink} onClick={handleClick}>
//         <Box border={1} borderColor="grey.300" borderRadius={3}>
//           <div className={classes.buttonContainer}>
//             <div className={classes.buttonIcon}>
//               <i className={clsx(button.icon, 'fa-fw')} />
//             </div>
//             <div className={classes.buttonLabel}>{button.label}</div>
//           </div>
//         </Box>
//       </NavLink>
//     </div>
//   )
// }

const homeButtonStyles = () => ({
  root: {},
  iconContainer: {},
  label: {},
})

const HomeButton = withStyles(homeButtonStyles, { name: 'AMSHomeButton' })(props => {
  const {
    classes,
    homeButtonBgColor,
    color = 'primary',
    variant = 'contained',
    elevation = 2,
    label = 'Unnamed Button',
    icon = 'fas fa-question',
    route: to = '/404',
    sx: buttonSx = {},
  } = props || {}

  // const dictBtnColor = {
  //   'immediate-help': 'transparent',
  //   'search-tile': '',
  //   'alcohol-drug-support': '#00a990',
  //   'mental-health-support': '#ffca09',
  // }
  const dictBtnIcons = {
    'immediate-help': <img src={IconImmediateHelp} />,
    'search-tile': <img src={IconSearchForService} />,
    'alcohol-drug-support': <img src={IconAnDSupport} />,
    'mental-health-support': <img src={IconMentalSupport} />,
  }
  // const bgColor = dictBtnColor[props.id] ? `${dictBtnColor[props.id]}!important` : ''
  const Icon = dictBtnIcons[props.id]
  return (
    <Button
      component={RouterLink}
      className={classes.root}
      variant={variant}
      color={color}
      elevation={elevation}
      style={{ backgroundColor: `${homeButtonBgColor}` }}
      to={to}
      sx={{
        background: 'white',
        flexDirection: 'column',
        p: 3,
        py: { xs: 2, md: 6 },
        justifyContent: 'flex-center',
        alignItems: 'flex-center',
        // ...buttonSx,
      }}
      fullWidth
    >
      <Box
        className={classes.iconContainer}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: { xs: '1.6rem', md: '2.38rem' },
          backgroundColor: 'transparent!important',
          // padding: '2px!important',
        }}
      >
        {Icon}
        {/* <Box as="i" className={icon} /> */}
      </Box>
      <Box
        className={classes.label}
        sx={{
          // minHeight: '4rem', // why? should be 2
          mt: 2,
          // minHeight: { xs: 'auto', md: '4rem' },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          fontSize: '1.125rem',
          color: '#814283',
        }}
      >
        {label}
      </Box>
    </Button>
  )
})

const homeButtonsStyles = theme => ({
  root: {},
})

const HomeButtonsMyServices = props => {
  const { classes, maxWidth = 'md', spacing = 2, gridProps } = props

  const { tenantConfig: tc } = useConfig()

  const config = tc.ui?.homeButtons || {}
  const { buttons } = config
  // console.log(tc.analytics.app)

  if (!buttons.length) return null

  return (
    <Box
      sx={{
        my: 3,
        // mx: -spacing,
        // margin: '0 auto',
        mx: 'auto',
        maxWidth,
      }}
    >
      <Grid container spacing={spacing}>
        {buttons.map((button, i) => (
          <Grid item key={i} {...{ xs: 12, md: 6, ...gridProps }}>
            <HomeButton {...button} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default withStyles(homeButtonsStyles, { name: 'AMSHomeButtons' })(HomeButtonsMyServices)
