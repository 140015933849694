import React, { useCallback, useState, useEffect } from 'react'
import DoneIcon from '@mui/icons-material/Done'

// import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'
import { useAnalytics } from 'use-analytics'

import { cloneDeep, merge } from 'lodash'

import { makeStyles, withStyles } from '@mui/styles'
import grey from '@mui/material/colors/grey'
import {
  Box,
  IconButton,
  Card,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material'

import { useConfig } from 'config/config'
import { useCountRenders } from 'ui/hooks/useCountRenders'

import { useResultsControl } from './ResultsControl'

import IconText from 'ui/components/atoms/IconText'
import { CieLink } from 'ui/nav/Link'
import Badge from './Badge'
import HealthEngineAppointment from './HealthEngineAppointment'
import NetworkMemberBadge from './NetworkMember'
import TrustedProviderLogo from '../../../tenants/myservices/assets/images/trustedprovider.png'

import ErrorBoundary from 'ui/components/atoms/ErrorBoundary'

const styles = theme => ({
  root: {
    borderRadius: 0,
    border: '1px solid rgba(0, 0, 0, 0.1)',
    boxShadow: 'none',
    transform: 'none',
    cursor: 'pointer',

    margin: theme.spacing(1.5, 1.5, 0.75),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1, 1, 0.5),
    },
  },
  // resultCard: {
  //   borderRadius: 0,

  //   border: '1px solid rgba(0, 0, 0, 0.1)',
  //   boxShadow: 'none',
  //   transform: 'none',
  // },
  selected: {
    border: 'none',
    boxShadow: '1px 2px 4px 0 rgba(0, 0, 0, 0.2)',
    transform: 'translate(0px, -1px)',
  },
  separator: {
    margin: theme.spacing(2, 0),
    height: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  spacer: {
    height: theme.spacing(3),
  },
  topInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(2),
  },
  headerIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  mcdIcon: {
    // maxHeight: 30,
    width: 22.5,
    height: 30,
  },
  datePill: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(0.7, 1),
    borderRadius: 3,
    fontSize: 18,
    fontWeight: 600,
  },
  datePillDiary: {
    fontSize: 16,
    fontWeight: 600,
    backgroundColor: '#417cbf',
    color: '#fff',
    padding: theme.spacing(0.7, 1),
    marginRight: theme.spacing(1),
    borderRadius: 3,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'stretch',
  },
  componentBlock: {
    width: '100%',
    margin: theme.spacing(2, 0),
  },
  actions: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(0),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& i': {
      fontSize: 18,
    },
  },
  distance: {
    marginLeft: 'auto',
    fontSize: 16,
    fontWeight: 300,
    color: grey[200],
  },
  logo: {
    maxHeight: 70,
    maxWidth: 120,
  },
  logoSmall: {
    maxHeight: 40,
    maxWidth: 90,
  },
  titleBlock: {
    margin: theme.spacing(0, 0, 2),
  },
  title: {
    fontSize: 18,
    fontWeight: 400,
  },
  category: {
    fontSize: 16,
    fontWeight: 300,
    color: theme.palette.text.secondary,
  },
  trustedprovider: {
    backgroundColor: '#814283',
    color: 'white',
    width: 'fit-content',
    padding: '8px 10px',
    borderRadius: '10px',
    display: 'flex',
  },
  wapahBadge: {
    display: 'flex',
    alignItems: 'center',
    border: '#01948e 1px solid',
    borderRadius: '5px',
    margin: '5px 0px',
    width: 'fit-content',
  },
  waphaIcon: { margin: '0px 5px' },
  wapahBadgeLogo: {
    backgroundColor: '#01948e',
    display: 'flex',
    alignItems: 'center',
    padding: '3px',
  },
  wapahBadgeText: { color: '#01948e', margin: '0px 5px' },
  // healthEngineContainer: {
  //   ...theme.typography.body1,
  //   // backgroundColor: '#f0f',
  //   width: '100%',
  //   display: 'flex',
  //   flexDirection: 'row',
  //   alignItems: 'flex-start',
  //   justifyContent: 'space-between',
  //   [theme.breakpoints.down('xs')]: {
  //     flexDirection: 'column',
  //   },
  // },
  // healthEngineAppointment: {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   alignItems: 'flex-start',
  //   justifyContent: 'fex-start',
  // },
  // healthEngineButton: {
  //   flexDirection: 'column',
  //   [theme.breakpoints.down('xs')]: {
  //     width: '100%',
  //     marginTop: theme.spacing(2),
  //   },
  // },
  // healthEngineButtonLabel: {
  //   flexDirection: 'column',
  //   fontSize: 12,
  // },
})

const useResultStyles = makeStyles(styles, { name: 'AMSSearchResult' })

// Mapped Components

const Separator = ({ classes }) => {
  return <div className={classes.separator} />
}

const Spacer = ({ classes }) => {
  return <div className={classes.spacer} />
}

const HeaderIcon = ({ classes, type, data }) => {
  const { tenantConfig } = useConfig()

  switch (type) {
    case 'directory':
      return (
        <div className={classes.headerIcon}>
          <img
            className={classes.mcdIcon}
            src={tenantConfig.assets.headerIcons.directory}
            alt="Directory Icon"
          />
        </div>
      )
    case 'diary':
      return (
        <div className={classes.headerIcon}>
          <div className={classes.datePillDiary}>
            {data.startDay} {data.startMonth}
          </div>
          <img
            className={classes.mcdIcon}
            src={tenantConfig.assets.headerIcons.diary}
            alt="Diary Icon"
          />
        </div>
      )
    default:
      return <div>{type}</div>
  }
}

const Header = ({ classes, config, data, variant }) => {
  const type = config?.options?.type
  const showSeparator = config?.options?.showSeparator
  const logoClass = variant === 'map' ? classes.logoSmall : classes.logo

  return (
    <>
      <div className={classes.topInfo}>
        {/* {data.type === 'event' && (
          <div>
            <div className={classes.datePill}>{data.startDay} {data.startMonth}</div>
          </div>
        )} */}
        {type !== 'logo' && <HeaderIcon classes={classes} type={type} data={data} />}
        {type === 'logo' && (
          <div>{data.logoUrl && <img className={logoClass} src={data.logoUrl} alt="logo" />}</div>
        )}
        <div className={classes.distance}>
          {data.distanceLabel && <IconText iconClass="fas fa-running" text={data.distanceLabel} />}
        </div>
      </div>
      {showSeparator && <Separator classes={classes} />}
    </>
  )
}

const useBadgeListStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    // flexWrap: 'wrap',
    margin: theme.spacing(1, 0, 3),
  },
}))

const BadgeList = ({ classes, config, data, variant }) => {
  const badgeClasses = useBadgeListStyles()

  const { options } = config

  const badges = data.badges.map(cst => merge(cloneDeep(options), cst))

  if (!badges || badges.length === 0) return null

  return (
    <div className={clsx(classes.componentBlock, badgeClasses.root)}>
      {badges.map((badge, i) => (
        <Badge key={i} {...badge} />
      ))}
    </div>
  )
}

const Title = ({ classes, config, data, variant }) => {
  const { track } = useAnalytics()

  if (!(data.name || data.category)) return null

  const handleTitleClick = e => {
    e.stopPropagation()
    track(variant === 'map' ? 'searchResultMapTitleClick' : 'searchResultTitleClick', {
      category: 'SearchResults',
      label: data.name,
    })
  }

  return (
    <div className={classes.titleBlock}>
      <CieLink
        className={classes.title}
        type={data.type}
        path={data.url}
        // color="secondary"
        onClick={handleTitleClick}
      >
        {data.name}
      </CieLink>
      {config.showCategory && <div className={classes.category}>{data.category}</div>}
    </div>
  )
}

const Description = ({ classes, data }) => {
  return (
    <Box className={classes.componentBlock}>
      <Typography
        variant="body1"
        element="div"
        dangerouslySetInnerHTML={{ __html: data.description }}
      />
    </Box>
  )
}

const Details = ({ classes, config, data }) => {
  let serviceTags = JSON.parse(JSON.stringify(data)).serviceTags
  const { tenantConfig: tc } = useConfig()

  if (!config.fields) return null

  const items = config.fields
    .filter(f => data[f.key] && data[f.key] !== '--withheld--')
    .map(f => ({
      icon: f.icon,
      text: data[f.key],
    }))
  const isWAPHA = serviceTags?.some(r => tc.ui.search?.tagWAPHA?.includes(r))

  const Icon = ({ src }) => {
    return (
      <div className={classes.wapahBadgeLogo}>
        <img className={classes.waphaIcon} height={30} src={src} />
      </div>
    )
  }

  return (
    <Box className={classes.componentBlock}>
      {items.map((v, i) => (
        <IconText key={i} iconClass={v.icon} text={v.text} />
      ))}
      <div>
        {data.TrustedProvider && (
          <div className={classes.trustedprovider}>
            <DoneIcon />
            Mental Health Commission Funded
          </div>
        )}
        {isWAPHA && (
          <div className={classes.wapahBadge}>
            <Icon src={tc.assets.badges.default} />
            <div className={classes.wapahBadgeText}>Commissioned by WAPHA</div>
          </div>
        )}
      </div>
    </Box>
  )
}

const AddressWithheld = ({ classes, data }) => {
  if (!data.addressWithheld) return null

  return (
    <Box className={classes.componentBlock}>
      <Typography
        variant="body1"
        // element="div"
        // dangerouslySetInnerHTML={{ __html: data.description }}
      >
        The maintainer of this service has elected to withhold the display of their location on a
        map.
      </Typography>
    </Box>
  )
}

const ServiceChanges = ({ classes, config, data }) => {
  if (!data.serviceChanges) return null

  return (
    <div className={classes.componentBlock}>
      <Separator classes={classes} />
      <IconText iconClass="fas fa-bullhorn" text="Service Changes" />
      <Details classes={classes} config={config} data={data.serviceChanges} />
    </div>
  )
}

const HealthEngine = ({ classes, data, ...otherProps }) => {
  if (!data.healthEngineAppointment) return null

  return (
    <Box className={classes.componentBlock}>
      <Separator classes={classes} />
      <HealthEngineAppointment {...{ data, ...otherProps }} />
    </Box>
  )
}

const NetworkMember = ({ classes, config, data }) => {
  const { tenantConfig: tc } = useConfig()
  tc.assets.allianceMember

  return (
    <Box
      className={classes.componentBlock}
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <NetworkMemberBadge config={config} data={data} />
    </Box>
  )
}

const componentMap = {
  Separator,
  Spacer,
  Header,
  BadgeList,
  Title,
  Description,
  Details,
  AddressWithheld,
  ServiceChanges,
  HealthEngine,
  NetworkMember,
}

// Actions
// TODO: Add to config

const Actions = observer(({ classes, data, search }) => {
  const { tenantConfig: tc } = useConfig()

  const { track } = useAnalytics()
  const [confirmDeleteFav, setConfirmDeleteFav] = useState(false)

  // const theme = useTheme()
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const icon = data.id.match(/_fav$/)
    ? 'fas fa-trash'
    : data.isFavourite
    ? 'fas fa-star'
    : 'far fa-star'

  const handleFavouriteToggle = e => {
    e.stopPropagation()

    if (data.isFavourite) {
      setConfirmDeleteFav(true)
    } else {
      track('searchResultAddFavourite', {
        category: 'SearchResults',
        label: data.name,
      })

      search.control.toggleFavourite(data)
    }
  }

  const handleClose = e => {
    e.stopPropagation()
    setConfirmDeleteFav(false)
  }

  const handleConfirm = e => {
    track('searchResultDeleteFavourite', {
      category: 'SearchResults',
      label: data.name,
    })

    e.stopPropagation()
    search.control.toggleFavourite(data)
    setConfirmDeleteFav(false)
  }

  if (!tc.search?.searches?.favourites) return null

  return (
    <div className={clsx(classes.componentBlock, classes.actions)}>
      <IconButton onClick={handleFavouriteToggle}>
        <i className={clsx(icon, 'fa-fw')} />
      </IconButton>

      <Dialog
        open={confirmDeleteFav}
        onClose={handleClose}
        // fullScreen={isMobile}
      >
        <DialogTitle>Remove Favourite</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove <strong>{data.name}</strong> from your favourites list?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="default" variant="contained" disableElevation onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            autoFocus
            disableElevation
            onClick={handleConfirm}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
})

// Result

const SearchResult = React.memo(
  ({
    // config,
    classes,
    search,
    data,
    variant = 'list',
    selected = false,
    onClickResult = () => undefined,
  }) => {
    // for optimising memoized renders

    // const classes = useResultStyles()

    // useCountRenders(data.id)

    // useEffect(() => {
    //   console.log(`effect[${data.id}]`)
    // }, [data, selected, onClickResult, search])

    const { tenantConfig: tc } = useConfig()
    const config = tc.ui?.search?.resultItem?.[data.type]

    if (!config) throw `Config for item type ${data.type} not found`

    const handleResultClick = useCallback(
      e => {
        e.stopPropagation()
        onClickResult(data)
      },
      [data, onClickResult]
    )

    const ResultContents = () => (
      <>
        {(config?.components || []).map((c, i) => {
          if (!(c.component in componentMap)) return null
          if (c.hideVariant === variant) return null
          const Component = componentMap[c.component]

          return (
            <Component
              key={i}
              classes={classes}
              config={c}
              search={search}
              data={data}
              variant={variant}
            />
          )
        })}
      </>
    )

    if (variant === 'map') {
      return <ResultContents />
    }

    return (
      <ErrorBoundary>
        <Card
          // variant="outlined"
          // className={classes.root}
          id={`result-${data.id}`}
          className={clsx(classes.root, selected && classes.selected)}
          elevation={selected ? 3 : 1}
          onClick={handleResultClick}
        >
          <CardContent sx={{ p: 2 }}>
            {false && <Typography variant="body1">{data.id}</Typography>}

            <ResultContents />

            <Actions classes={classes} {...{ search, data }} />
          </CardContent>
        </Card>
      </ErrorBoundary>
    )
  }
)

SearchResult.displayName = 'AMSSearchResult'

export default React.memo(withStyles(styles, { name: 'AMSSearchResult' })(SearchResult))
// export default SearchResult
// export default SearchResult2
